import { createSlice } from '@reduxjs/toolkit';


const initialState: any = {}
export const accessPolicySlice = createSlice({
  name: 'accessPolicyState',
  initialState,
  reducers: {
    setInitAccessPolicy: (state, action) => {
      const temp: any = {};
      action.payload.features.forEach((a: any) => (temp['f_' + a.key + '_allowed'] = a.allowed));
      action.payload.resources.forEach((a: any) => {
        temp['r_' + a.key + '_allowed'] = a.allowed;
        let resourceOperations = '';
        resourceOperations += a.operations.createAllowed ? '1' : '';
        resourceOperations += a.operations.readAllowed ? '2' : '';
        resourceOperations += a.operations.updateAllowed ? '3' : '';
        resourceOperations += a.operations.deleteAllowed ? '4' : '';
        temp['r_' + a.key + '_operations'] = resourceOperations;

        a.attributes.forEach((b: any) => {
          temp['a_' + b.key + '_allowed'] = b.allowed;
          let AttrOperations = '';
          AttrOperations += b.operations.readAllowed ? '2' : '';
          AttrOperations += b.operations.updateAllowed ? '3' : '';
          temp['a_' + b.key + '_operations'] = AttrOperations;
        });
      });
      temp['r_message_allowed'] = true;
      temp['r_message_operations'] = '';
      temp['r_tasks_allowed'] = true;
      temp['r_tasks_operations'] = '';
      temp['r_custom_audit_list_allowed'] = true;
      temp['r_custom_audit_list_operations'] = '';
      temp['r_message_operations'] = '';
      temp['a_keywords_allowed'] = true;
      temp['a_keywords_operations'] = '';
      temp['a_search_by_certification_type_allowed'] = true;
      temp['a_search_by_certification_type_operations'] = '';
      temp['a_search_by_expiration_dates_allowed'] = true;
      temp['a_search_by_expiration_dates_operations'] = '';
      temp['a_search_by_registration_date_allowed'] = true;
      temp['a_search_by_registration_date_operations'] = '';
      temp['a_search_by_business_categories_allowed'] = true;
      temp['a_search_by_business_categories_operations'] = '';
      temp['a_search_by_naics_codes_allowed'] = true;
      temp['a_search_by_naics_codes_operations'] = '';
      temp['a_search_by_sic_codes_allowed'] = true;
      temp['a_search_by_sic_codes_operations'] = '';
      temp['a_search_by_service_type_allowed'] = true;
      temp['a_search_by_service_type_operations'] = '';
      return temp;
    },
  },
});

export const { setInitAccessPolicy } = accessPolicySlice.actions;

export default accessPolicySlice.reducer;
