import { configureStore } from '@reduxjs/toolkit';
import appStateReducer from './slices/appStateSlice';
import mainStateReducer from './slices/mainStateSlice';
import accessPolicyReducer from './slices/accessPolicySlice';
// import mapReducer from './slices/mapSlice';
import { searchReducer } from './slices/searchSlice';

export const store = configureStore({
    reducer: {
        appState: appStateReducer,
        mainState: mainStateReducer,
        access_policy: accessPolicyReducer,
        // mapState: mapReducer,
        search: searchReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
