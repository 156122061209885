import React, { useEffect, Suspense } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { isExpired } from 'react-jwt';
import awsConfig from './config/aws-exports';
import { Route, Switch, useHistory, HashRouter } from 'react-router-dom';
import axios from 'axios';
import { useAppDispatch } from './redux/reduxHooks';
import { clearAppUser, setAppUser } from './redux/slices/appStateSlice';
import loadable from '@loadable/component';
import './App.scss';
import Fallback from './components/supports/Fallback';
import { Ripple } from '@progress/kendo-react-ripple';

const auth = loadable(() => import('./components/layouts/auth/AuthLayout'), {
  fallback: <Fallback />,
});
const app = loadable(() => import('./components/layouts/app/AppLayout'), {
  fallback: <Fallback />,
});

function App() {
  Amplify.configure(awsConfig);
  const history = useHistory();
  const dispatch = useAppDispatch();


  useEffect(() => {
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        const token = user.signInUserSession.accessToken.jwtToken;
        const isMyTokenExpired = isExpired(token);
        if (isMyTokenExpired) {
          localStorage.clear();
          dispatch(clearAppUser());
          history.replace('/');
        } else {
          axios.defaults.headers.Authorization = 'Bearer ' + token;
          axios.interceptors.response.use(
            (response) => {
              return response;
            },
            (error) => {
              if (error.response.status === 401) {
                dispatch(clearAppUser());
                localStorage.clear();
              } else {
                return Promise.reject(error);
              }
            }
          );
          dispatch(
            setAppUser({
              username: user.username,
              attributes: user.attributes,
              token: token,
            })
          );
        }
      })
      .catch((err) => {
        console.log('auth.currentAuthenticatedUser:', err);
        dispatch(clearAppUser());
        localStorage.clear();
      });
  }, [dispatch, history]);

  return (
    <HashRouter>
      <Suspense fallback={''}>
        <Switch>
          <Ripple>
            <Route exact path="/" component={auth} />
            <Route path="/signup" component={auth} />
            <Route path="/app" component={app} />
            <Route path="/auth" component={auth} />
          </Ripple>
        </Switch>
      </Suspense>
    </HashRouter>
  );
}

export default App;
