import { createSlice } from '@reduxjs/toolkit';
import { Company, IMainState, Supplier } from '../../models/appLayout/appLayout.interface';

const initialState: IMainState = {
  darkState: false,
  selectOptions: {
    annual_revenues: [],
    business_categories: [],
    certification_types: [],
    customer_bases: [],
    entity_types: [],
    ethnicity_types: [],
    naics_codes: [],
    number_of_employees: [],
    ownership_types: [],
    service_types: [],
    service_types_display: [],
    sic_codes: [],
    local_service_area: [],
  },
  Company: {} as Company,
  Supplier: {} as Supplier,
  image_bucket: '',
  certificate_bucket: '',
  
  certificates: [],
  document_types:[{label:'Capability Statement',value:'CAPABILITY'},{label:'ESG Plan',value:'ESG'}],
  tracker: {
    id: 0,
    supplier_info: false,
    company_location: false,
    company_service_product: false,
    certification: false,
    review: false,
    company_id: 0,
    optin: false,
  },
 
  
};

export const mainStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setSelectOptions: (state, action) => {
      state.selectOptions = action.payload;
    },
    setDarkState: (state, action) => {
      state.darkState = action.payload;
    },

    setCompany: (state, action) => {
      state.Company = action.payload.Company;
      state.Supplier = action.payload.Supplier;
      state.tracker = action.payload.tracker;
      state.certificate_bucket = action.payload.certificate_bucket;
      state.certificates = action.payload.certificates;
      state.image_bucket = action.payload.image_bucket;
    },
    setCompanyAndTracker: (state, action) => {
      state.Company = action.payload.Company;
      state.tracker = action.payload.tracker;
    },
    setCompanyImage: (state, action) => {
      state.Company.image_detail = action.payload;
    },
    setSupplierImage: (state, action) => {
      state.Supplier.image_detail = action.payload;
    },
    setSupplier: (state, action) => {
      state.Supplier = action.payload;
    },
    setTracker: (state, action) => {
      state.tracker = action.payload.tracker;
    },
    setSupplierSetting: (state, action) => {
      state.Supplier.user_settings = action.payload;
    },
    setTrackerAndCertificates: (state, action) => {
      state.tracker = action.payload.tracker;
      state.certificates = action.payload.certificates;
    },
    setCertificates: (state, action) => {
      state.certificates = action.payload.certificates;
    },
    setSupplierSettingLocale: (state, action) => {
      state.Supplier.user_settings.locale = action.payload;
    },
  },
});

export const {
  setSelectOptions,
  setSupplierSetting,
  setCompany,
  setCompanyAndTracker,
  setTracker,
  setCompanyImage,
  setSupplierImage,
  setSupplier,
  setDarkState,
  setCertificates,
  setTrackerAndCertificates,
  setSupplierSettingLocale,
} = mainStateSlice.actions;

export default mainStateSlice.reducer;
