import { createSlice } from '@reduxjs/toolkit';

interface AppState {
  user: {
    isLoggedIn: boolean;
    username: string;
    password?: string;
    email: string;
    user_role?: number;
    tax_id?: string;
    enterprise?: string;
    company_name?: string;
    phone_number?: number;
    phone_extension?: number;
  };
  languageState: any;
  notifications: Array<any>;
  mainSnackbarOpen: boolean;
  mainSnackbarMessage: string;
  mainSnackbarType: string;
}

const initialState: AppState = {
  user: {
    isLoggedIn: false,
    username: '',
    email: '',
    user_role: undefined,
    company_name: '',
    phone_number: undefined,
    phone_extension: undefined,
  },
  notifications: [],
  languageState: {},
  mainSnackbarOpen: false,
  mainSnackbarMessage: '',
  mainSnackbarType: 'success',
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setAppUser: (state, action) => {
      state.user = {
        isLoggedIn: true,
        username: action.payload.username,
        email: action.payload.attributes.email,
      };
    },
    setSignedIn: (state) => {
      state.user.isLoggedIn = true;
    },
    signUpSetUser: (state, action) => {
      state.user.username = action.payload.username;
      state.user.password = action.payload.password;
      state.user.company_name = action.payload.company_name;
      state.user.email = action.payload.email;
      state.user.enterprise = action.payload.enterprise;
      state.user.phone_number = action.payload.phoneNumber;
      state.user.tax_id = action.payload.taxID;
      state.user.user_role = action.payload.userrole;
    },
    clearAppUser: (state) => {
      state.user = {
        isLoggedIn: false,
        username: '',
        email: '',
        user_role: undefined,
        company_name: '',
        phone_number: undefined,
        phone_extension: undefined,
      };
    },
    openSnackbar: (state, action) => {
      state.mainSnackbarOpen = action.payload.mainSnackbarOpen;
      state.mainSnackbarMessage = action.payload.mainSnackbarMessage;
      state.mainSnackbarType = action.payload.mainSnackbarType;
    },
    closeSnackbar: (state) => {
      state.mainSnackbarOpen = false;
      state.mainSnackbarMessage = '';
      state.mainSnackbarType = 'success';
    },
    setLanguageState: (state, action) => {
      state.languageState = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const {
  setAppUser,
  clearAppUser,
  signUpSetUser,
  openSnackbar,
  closeSnackbar,
  setLanguageState,
  setNotifications,
  setSignedIn
} = appStateSlice.actions;

export default appStateSlice.reducer;
